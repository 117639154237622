<template>
  <b-card>
    <error-alert
      :errors="errors"
      message="Виникла помилка при пошуку поточного моніторингу!"
    />
    <ValidationObserver ref="formFilterValidation" v-slot="{ handleSubmit }">
      <b-form>
        <b-form-row>
          <b-col md="6" lg="4" xl="4">
            <ValidationProvider
              name="період"
              v-slot="{ errors, dirty, validated, valid, changed }"
              vid="date_gte"
            >
              <b-form-group label="Зазначте період">
                <date-range-picker
                  :state="
                    setValidationState(errors, dirty, validated, valid, changed)
                  "
                  :start-date="filter.processedAtFrom.value"
                  :end-date="filter.processedAtTo.value"
                  v-on:start-date-change="changeDateFrom"
                  v-on:end-date-change="changeDateTo"
                  :time-picker="false"
                  :format-date="'dd.mm.yyyy'"
                  style="width: 100%"
                />
                <b-form-invalid-feedback
                  :state="errors.length === 0"
                  v-for="(error, index) in errors"
                  v-bind:key="index"
                >
                  {{ error }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="6" lg="2" xl="2">
            <ValidationProvider
              name="валюта"
              v-slot="{ errors, dirty, validated, valid, changed }"
              vid="currency_where"
            >
              <b-form-group label="Валюта">
                <b-form-select
                  v-model="filter.currency.value"
                  :options="currencyList"
                  :state="
                    setValidationState(errors, dirty, validated, valid, changed)
                  "
                >
                  <template #first>
                    <b-form-select-option value=""></b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback
                  :state="errors.length === 0"
                  v-for="(error, index) in errors"
                  v-bind:key="index"
                >
                  {{ error }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="6" lg="3">
            <ValidationProvider
              name="профіль спфм"
              v-slot="{ errors, dirty, validated, valid, changed }"
              vid="financial_model_id_where"
            >
              <b-form-group label="Профіль СПФМ">
                <b-form-select
                  v-model="filter.financialModelId.value"
                  :options="meFinancialModels"
                  value-field="id"
                  text-field="name"
                  :state="
                    setValidationState(errors, dirty, validated, valid, changed)
                  "
                >
                  <template #first>
                    <b-form-select-option value=""></b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback
                  :state="errors.length === 0"
                  v-for="(error, index) in errors"
                  v-bind:key="index"
                >
                  {{ error }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="6" lg="3">
            <ValidationProvider
              name="належність до публічних осіб, до осіб, близьких або пов'язаних з публічними особами"
              v-slot="{ errors, dirty, validated, valid, changed }"
              vid="is_public"
            >
              <b-form-group
                label="Належність до публічних осіб, до осіб, близьких або пов'язаних з публічними особами"
              >
                <b-form-select
                  v-model="filter.isPublic.value"
                  :options="isPublicTypes"
                  text-field="name"
                  value-field="value"
                  :state="
                    setValidationState(errors, dirty, validated, valid, changed)
                  "
                >
                  <template #first>
                    <b-form-select-option value=""></b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback
                  :state="errors.length === 0"
                  v-for="(error, index) in errors"
                  v-bind:key="index"
                >
                  {{ error }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="8" lg="9">
            <ValidationProvider
              name="рівень ризику"
              v-slot="{ errors, dirty, validated, valid, changed }"
              vid="crime_risk_levels"
            >
              <b-form-group label="Контрагент">
                <multiselect
                  v-model="filter.counterpartIds.value"
                  :options="counterparts.map((e) => e.id)"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :show-labels="false"
                  placeholder=""
                  :custom-label="customCounterpartNameLabel"
                >
                  <template class="checkbox-label" v-slot:option="scope">
                    <input
                      type="checkbox"
                      :checked="
                        filter.counterpartIds.value.includes(scope.option)
                      "
                      @focus.prevent
                    />
                    {{ customCounterpartNameLabel(scope.option) }}
                  </template>
                </multiselect>
                <b-form-invalid-feedback
                  :state="errors.length === 0"
                  v-for="(error, index) in errors"
                  v-bind:key="index"
                >
                  {{ error }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="4" lg="3">
            <ValidationProvider
              name="рівень ризику"
              v-slot="{ errors, dirty, validated, valid, changed }"
              vid="crime_risk_levels"
            >
              <b-form-group label="Рівень ризику">
                <multiselect
                  v-model="filter.crimeRiskLevelIds.value"
                  :options="crimeRiskLevels.map((e) => e.id)"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :show-labels="false"
                  placeholder=""
                  :custom-label="customCrimeRiskLevelsLabel"
                >
                  <template class="checkbox-label" v-slot:option="scope">
                    <input
                      type="checkbox"
                      :checked="
                        filter.crimeRiskLevelIds.value.includes(scope.option)
                      "
                      @focus.prevent
                    />
                    {{ customCrimeRiskLevelsLabel(scope.option) }}
                  </template>
                </multiselect>
                <b-form-invalid-feedback
                  :state="errors.length === 0"
                  v-for="(error, index) in errors"
                  v-bind:key="index"
                >
                  {{ error }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <b-button
              variant="primary"
              @click="getMonitoring()"
              class="mb-2"
              :disabled="submitting"
            >
              <i class="fa fa-search"></i> Пошук
            </b-button>
          </b-col>
          <b-col class="text-right justify-content-end">
            <b-button
              class="mb-2"
              variant="primary"
              @click="exportData"
              :disabled="submittingExport || meta.total === 0"
              v-if="$auth.can($stringConstants('PERMISSION_EXPORT_MONITORING'))"
            >
              <i class="fa fa-dot-circle-o"></i> Вивантажити xlsx
            </b-button>
          </b-col>
        </b-form-row>
      </b-form>
    </ValidationObserver>
    <b-table
      show-empty
      :responsive="true"
      :hover="true"
      :bordered="true"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :items="items"
      :fields="fields"
      :busy="submitting"
    >
      <template #table-busy>
        <div class="text-center text-dark my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Завантаження...</strong>
        </div>
      </template>

      <template #thead-top="data">
        <b-tr>
          <b-th colspan="4"></b-th>
          <b-th colspan="4">Збільшення активів</b-th>
          <b-th colspan="4">Зменшення активів</b-th>
          <b-th colspan="3"></b-th>
        </b-tr>
      </template>

      <template v-slot:cell(counterpart_name)="data">
        <b-link
          target="_blank"
          :to="{
            name: 'counterpart',
            params: { id: data.item.counterpart_id },
          }"
        >
          {{ data.value }}
        </b-link>
      </template>

      <template #empty="scope">
        <div class="h4 text-center">Дані не знайдено</div>
      </template>

      <template v-slot:cell(total_amount_payee)="data">
        <b-link
          target="_blank"
          :to="{
            name: 'operations',
            query: createQuery(data.item, 'payee'),
          }"
        >
          {{ data.value }}
        </b-link>
      </template>

      <template v-slot:cell(total_original_amount_payee)="data">
        <b-link
          target="_blank"
          :to="{
            name: 'operations',
            query: createQuery(data.item, 'payee'),
          }"
        >
          {{ data.value }}
        </b-link>
      </template>

      <template v-slot:cell(total_amount_payer)="data">
        <b-link
          target="_blank"
          :to="{
            name: 'operations',
            query: createQuery(data.item, 'payer'),
          }"
        >
          {{ data.value }}
        </b-link>
      </template>

      <template v-slot:cell(total_original_amount_payer)="data">
        <b-link
          target="_blank"
          :to="{
            name: 'operations',
            query: createQuery(data.item, 'payer'),
          }"
        >
          {{ data.value }}
        </b-link>
      </template>
    </b-table>

    <b-row>
      <b-col cols="auto" class="mr-auto p-3">
        <b-pagination
          v-model="meta.page"
          prev-text="Попередня"
          next-text="Наступна"
          hide-goto-end-buttons
          :per-page="meta.per_page"
          :total-rows="meta.total"
          @change="onChangePage"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import api from "@/api/api";
import moment from "moment/moment";
import DateRangePicker from "@/components/DateRangePickerWrapper";
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import ErrorAlert from "@/components/ErrorAlert.vue";
import mixins from "@/mixins";
import queryFilter from "@/shared/filterQuery";
import qs from "qs";
import debounce from "debounce";

export default {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$auth.can(vm.$stringConstants("PERMISSION_VIEW_MONITORING")))
        next();
      else next({ name: "Page404" });
    });
  },
  name: "Monitoring",
  components: { ErrorAlert, DateRangePicker, Multiselect },
  mixins: [mixins],
  created() {
    this.debouncedGetMonitoringList = debounce(this.getMonitoring, 500);
  },
  data() {
    return {
      sortBy: "",
      sortDesc: false,
      meta: {
        total: 0,
        page: 1,
        per_page: 20,
        sort_by: "",
      },
      currencyList: ["UAH", "USD", "EUR", "PLN", "CZK"],
      isPublicTypes: [
        {
          name: "Так",
          value: 1,
        },
        {
          name: "Ні",
          value: 0,
        },
      ],
      date_from: moment(new Date()).startOf("day").toDate(),
      date_to: moment(new Date()).toDate(),
      spfmFields: [
        {
          key: "name",
          label: "Профіль СПФМ",
        },
        {
          key: "count",
          label: "К-сть",
          tdClass: "text-right",
        },
        {
          key: "sum",
          label: "Оборот за період",
          formatter: (v) => v.toFixed(2),
          tdClass: "text-right",
        },
      ],
      filter: {},
      fields: [
        {
          key: "counterpart_name",
          label: "Назва контрагента",
        },
        {
          key: "code",
          label: "ЄДРПОУ/РНОКПП",
        },
        {
          key: "financial_model_name",
          label: "Пр. СПФМ",
        },
        {
          key: "currency",
          label: "Валюта",
        },
        {
          key: "total_operations_payee",
          label: "К-сть",
          sortable: true,
          formatter: (v) => new Intl.NumberFormat("uk-UA").format(v),
          tdClass: "text-right",
          thClass: "text-right",
        },
        {
          key: "total_amount_payee",
          label: "Оборот",
          sortable: true,
          formatter: (v) =>
            new Intl.NumberFormat("uk-UA", { minimumFractionDigits: 2 }).format(
              v
            ),
          tdClass: "text-right",
          thClass: "text-center",
        },
        {
          key: "total_original_amount_payee",
          label: "Оборот без ком.",
          sortable: true,
          formatter: (v) =>
            new Intl.NumberFormat("uk-UA", { minimumFractionDigits: 2 }).format(
              v
            ),
          tdClass: "text-right",
          thClass: "text-center",
        },
        {
          key: "last_operation_datetime_payee",
          label: "Ост. операція",
          formatter: (v) =>
            v ? moment(v).format("DD.MM.YYYY, HH:mm:ss") : null,
          tdClass: "text-right",
          thClass: "text-right",
        },
        {
          key: "total_operations_payer",
          label: "К-сть",
          sortable: true,
          formatter: (v) => new Intl.NumberFormat("uk-UA").format(v),
          tdClass: "text-right",
          thClass: "text-right",
        },
        {
          key: "total_amount_payer",
          label: "Оборот",
          sortable: true,
          formatter: (v) =>
            new Intl.NumberFormat("uk-UA", { minimumFractionDigits: 2 }).format(
              v
            ),
          tdClass: "text-right",
          thClass: "text-center",
        },
        {
          key: "total_original_amount_payer",
          label: "Оборот без ком.",
          sortable: true,
          formatter: (v) =>
            new Intl.NumberFormat("uk-UA", { minimumFractionDigits: 2 }).format(
              v
            ),
          tdClass: "text-right",
          thClass: "text-center",
        },
        {
          key: "last_operation_datetime_payer",
          label: "Ост. операція",
          formatter: (v) =>
            v ? moment(v).format("DD.MM.YYYY, HH:mm:ss") : null,
          tdClass: "text-right",
          thClass: "text-right",
        },
        {
          key: "site",
          label: "Сайт",
          tdClass: "text-right",
          thClass: "text-center",
        },
        {
          key: "identified_at",
          label: "Дата ідентифікації",
          formatter: (v) => (v ? moment(v).format("DD.MM.YYYY") : null),
          tdClass: "text-right",
          thClass: "text-center",
        },
        {
          key: "identification",
          label: "Статус ідентифікації",
          thClass: "text-center",
        },
      ],
      items: [],
      errors: {},
      submitting: false,
      submittingExport: false,
    };
  },
  computed: {
    ...mapGetters({
      counterparts: "contracts/getAllCounterparts",
      meFinancialModels: "auth/meFinancialModels",
      crimeRiskLevels: "dictionary/allCounterpartCrimeRiskLevels",
    }),
    sort: function () {
      if (this.sortDesc) return "-" + this.sortBy;
      return this.sortBy;
    },
  },
  beforeMount() {
    this.filter = this.getDefaultFilter();
    this.setDefaultFilter();
  },
  methods: {
    getDefaultFilter: function () {
      return {
        processedAtFrom: {
          name: "date",
          type: "gte",
          date: true,
          value: moment().startOf("day").toDate(),
        },
        processedAtTo: {
          name: "date",
          type: "lte",
          date: true,
          value: moment().toDate(),
        },
        financialModelId: {
          name: "financial_model_id",
          type: "where",
          value: "",
        },
        currency: {
          name: "currency",
          type: "where",
          value: "",
        },
        counterpartIds: {
          name: "counterpart_id",
          type: "whereIn",
          value: [],
        },
        isPublic: {
          name: "is_public",
          type: "where",
          value: "",
        },
        crimeRiskLevelIds: {
          name: "crime_risk_level_id",
          type: "whereIn",
          value: [],
        },
      };
    },
    changeCurrentPage: function () {
      this.debouncedGetMonitoringList();
    },
    setDefaultFilter: function () {
      const query = Object.assign({}, this.$route.query);
      const newFilter = queryFilter.methods.getFilterFromQuery(
        query,
        this.getDefaultFilter()
      );
      this.$set(this, "filter", newFilter);
      this.changeCurrentPage();
    },
    changeDateFrom: function (v) {
      this.filter.processedAtFrom.value = v;
    },
    changeDateTo: function (v) {
      this.filter.processedAtTo.value = v;
    },
    onChangePage(page) {
      this.getMonitoring(page);
    },
    prepareFilterParams: function () {
      const filters = {};
      Object.entries(this.filter).forEach(([, filter]) => {
        if (
          filter.value !== null &&
          filter.value !== "" &&
          filter.value.length !== 0
        ) {
          filters[`filter[${filter.name}@${filter.type}]`] = filter.date
            ? moment(filter.value).format("YYYY-MM-DD")
            : filter.value;
        }
      });
      return {
        page: this.meta.page,
        per_page: this.meta.per_page,
        ...filters,
      };
    },
    getMonitoring(page = 1) {
      this.errors = {};
      this.$refs.formFilterValidation.validate().then((success) => {
        if (!success) {
          return;
        }

        this.submitting = true;
        this.items = [];
        this.meta.page = page;
        api
          .getMonitoring(this.prepareFilterParams())
          .then(({ data }) => {
            this.submitting = false;
            this.items = data.data;
            this.meta.total = data.meta.total;
          })
          .catch(({ response }) => {
            this.submitting = false;
            this.$set(this, "errors", response.data);
            if (response.status === 400) {
              this.$refs.formFilterValidation.setErrors(
                this.errors.description
              );
            }
          });
      });
    },
    exportData: function () {
      this.errors = {};
      this.$refs.formFilterValidation.validate().then((success) => {
        if (!success) {
          return;
        }

        this.submittingExport = true;
        api
          .exportCounterpartsMonitoring(this.prepareFilterParams())
          .then(() => {
            this.$snotify.success("Файл буде надіслано на вашу пошту");
          })
          .catch(({ response }) => {
            this.$set(this, "errors", response.data);
            if (response.status === 422) {
              this.$refs.formFilterValidation.setErrors(
                this.errors.description
              );
            }
          })
          .finally(() => (this.submittingExport = false));
      });
    },
    createQuery(item, $format) {
      const query = {};
      if (this.filter.processedAtFrom.value) {
        const processedAtFromDate = this.filter.processedAtFrom.value;
        processedAtFromDate.setHours(0, 0, 0);
        query.processedAtFrom = processedAtFromDate.toISOString();
      }

      if (this.filter.processedAtTo.value) {
        const processedAtToDate = this.filter.processedAtTo.value;
        processedAtToDate.setHours(23, 59, 59);
        query.processedAtTo = processedAtToDate.toISOString();
      }

      if (this.filter.financialModelId.value) {
        query.financialModelId = this.filter.financialModelId.value;
      }

      if (this.filter.currency.value) {
        query.currency = this.filter.currency.value;
      }

      if ($format === "payer" && item.counterpart_id) {
        query["payerCounterpartId"] = item.counterpart_id;
      }

      if ($format === "payee" && item.counterpart_id) {
        query["payeeCounterpartId"] = item.counterpart_id;
      }

      return query;
    },
    customCrimeRiskLevelsLabel(id) {
      return this.crimeRiskLevels.find((e) => e.id === id).name;
    },
    customCounterpartNameLabel(id) {
      const counterpart = this.counterparts.find((e) => e.id === id);

      return `${counterpart.name} — [${counterpart.financial_model.name}]
      - [${counterpart.code}]
      ${counterpart.site ? " - [" + counterpart.site + "]" : ""}`;
    },
  },
  watch: {
    sort: function () {
      this.params.sort_by = this.sort;
      this.getMonitoring();
    },
    filter: {
      deep: true,
      handler(filter) {
        const newQuery = queryFilter.methods.getQueryFromFilter({}, filter);
        if (
          qs.stringify(this.$route.query) !==
          qs.stringify(newQuery, { arrayFormat: "comma" })
        )
          this.$router.replace({ query: newQuery });
      },
    },
  },
};
</script>
